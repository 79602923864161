export default function listenForOutsideClicks(
  setDropdownPopoverShow,
  popoverDropdownRef,
  btnDropdownRef
) {
  const handleClickOutside = (evt) => {
    const cur = popoverDropdownRef.current;
    const btn = btnDropdownRef.current;
    const node = evt.target;

    if (cur && cur.contains(node)) return; // Click inside dropdown
    if (btn && btn.contains(node)) return; // Click on the button

    setDropdownPopoverShow(false); // Click outside, close the dropdown
  };

  const addEventListeners = () => {
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
  };

  const removeEventListeners = () => {
    document.removeEventListener("click", handleClickOutside);
    document.removeEventListener("touchstart", handleClickOutside);
  };

  return { addEventListeners, removeEventListeners };
}
